import React from "react";
import PropTypes from "prop-types";
import InnerPageBanner from "components/inner_page_banner";
import { Link, graphql, StaticQuery } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import KG2Analytics from "components/kg2_analytics";
import CTABar2 from "components/cta_bar_2";
import LargeHeroBanner from "components/large_hero_banner";

const ServiceCard = ({ title, description, thumbnail_image, path }) => {
  const navigate = () => {
    window.location.href = path;
  };
  return (
    <div
      className=""
      onClick={navigate}
      style={{
        cursor: "pointer",
        // border: "1px solid #D9963D",
        borderRadius: "40px",
        // backgroundColor: "#D9963D",
      }}
    >
      <div className="service-layout2" onClick={navigate}>
        <div style={{ textAlign: "center" }} className="service-layout5">
          <img
            src={`/${thumbnail_image}`}
            alt="values"
            className="img-fluid width-100 mb-3"
            style={{
              height: "200px",
              objectFit: "cover",
              borderRadius: "15px",
              // filter: "grayscale(100%)",
            }}
          />
          {/* <div className="item-icon">
                    <i className="flaticon-insurance-1" />
                </div> */}
          {/* <div style={{
                      backgroundImage: `/${thumbnail_image}`, 
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      maxHeight: "250px",
                      minHeight: "250px",
                      height: "250px"
                    }} alt="service" /> */}
        </div>
        <h3 className="title title-medium size-xs color-dark-accent hover-color-accent text-center">
          {title}
        </h3>
        {/* <p style={{cursor: "pointer"}}>{description}</p> */}
        {/* <a href={path} className="btn-ghost btn-icon icon-right size-xs radius-4">Read More<i className="fas fa-caret-right" /></a> */}
      </div>
    </div>
  );
};

const ServicesPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <>
      <div id="header-area-space" className="slider-area bg-light-primary">
        <LargeHeroBanner bgImage={"/img/banner/services_banner.jpg"}>
          <h2>
            <strong>Services</strong>
          </h2>
          <br />
          <h2>Finstead Risk Solutions</h2>
          <h2>
            is the <strong>future</strong> of the
          </h2>
          <h2> insurance broking industry</h2>
          <br />
          {/* <a
            href="/contact"
            className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
          >
            Call Now
            <i className="fas fa-angle-right" />
          </a> */}
        </LargeHeroBanner>
      </div>

      <CTABar2 />
      <br />
      <br />
      <br />
      <div className="w-100 text-center mb-4">
        <h2
          style={{ fontWeight: "300" }}
          className="title title-regular size-xs mt-5"
        >
          Our Services
        </h2>
      </div>
      <br />

      <div className="container mt-5 pt-5">
        <div
          className="rc-carousel nav-control-custom"
          data-loop="true"
          data-items={4}
          data-margin={30}
          data-autoplay="true"
          data-autoplay-timeout={5000}
          data-smart-speed={2000}
          data-dots="false"
          data-nav="false"
          data-nav-speed="false"
          data-r-x-small={2}
          data-r-x-small-nav="false"
          data-r-x-small-dots="false"
          data-r-x-medium={2}
          data-r-x-medium-nav="false"
          data-r-x-medium-dots="false"
          data-r-small={2}
          data-r-small-nav="false"
          data-r-small-dots="false"
          data-r-medium={4}
          data-r-medium-nav="false"
          data-r-medium-dots="false"
          data-r-large={4}
          data-r-large-nav="false"
          data-r-large-dots="false"
        >
          <ServiceCard
            {...{
              path: "",
              title: "Professional & Financial Risk",
              description: "",
              thumbnail_image:
                "img/services/professional_and_financial_risk.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Corporate and Commercial",
              description: "",
              thumbnail_image: "img/services/corporate_and_commercial.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Group & Affinity",
              description: "",
              thumbnail_image: "img/services/group_and_affinity.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Risk Advisory",
              description: "",
              thumbnail_image: "img/services/risk_advisory.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "SME",
              description: "",
              thumbnail_image: "img/services/SME.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "M&A",
              description: "",
              thumbnail_image: "img/services/M&A.jpg",
            }}
          />
        </div>
        <br />
        <br />
        <div className="w-100 text-center">
          <p style={{ textAlign: "justify" }}>
            There are numerous ways to protect a business. Risk identification
            is the starting point. Our team of professionals will assist you in
            this process by identifying the insurance products that best suit
            your needs. We will simplify this process and help you navigate the
            range of product structures, terms, conditions and pricing. Simply
            put, our Finstead professionals will do the heavy lifting for you
            and we will work within your time-frames and pricing requirements to
            deliver the best option in the market.
          </p>
          <p style={{ textAlign: "justify" }}>
            We work with credible insurers globally and, most importantly,
            represent your interests in any risk-transfer arrangement. We take a
            holistic approach in managing client needs by working closely with
            our clients to become a trusted insurance partner, and delivering
            tailored solutions that support the growth and reputation of your
            business.
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const query = () => (
  <StaticQuery
    query={graphql`
      query ServicesPageQuery {
        allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id

              frontmatter {
                path
                title
                description
                thumbnail_image
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ServicesPage data={data} count={count} />}
  />
);

export default query;
