import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export const KG2FormSubmit = function () {
  // typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
  // window.gtag("event", "conversion", { allow_custom_scripts: true, send_to: 'DC-11256948/finsconv/formconv+standard' });
};

const KG2Analytics = () => {
  useEffect(() => {
    // trigger on page visit
    // typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag("event", "conversion", { allow_custom_scripts: true, send_to: 'DC-11256948/finsrema/allpages+standard' })
    // check if the current page is the kg2 lead page and trigger page visit for that page
    // typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.pathname == '/KG2' && window.gtag("event", "conversion", { allow_custom_scripts: true, send_to: 'DC-11256948/finsconv/pageconv+standard' })
  }, []);

  return (
    <>
      {/* Load gtag into window object */}
      {/* <Helmet script={[{type: 'text/javascript', innerHTML: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NDGXGZ6');"}]}/> */}

      {/* <Helmet>
                <body>
                    {`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NDGXGZ6" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`}
                </body>
            </Helmet> */}

      {/* Google Tag Manager for KG2 and Finstead */}
      {/* <Helmet script={[{type: 'text/javascript', innerHTML: FinsteadAndKg2GTMScript }]}/> */}

      {/* Google Tag Manager No script */}
      {/* <Helmet><body>{`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NDGXGZ6" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`}</body></Helmet> */}

      {/* Pixel for all page visits */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsrema;cat=allpages;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="0" height="0" alt="" /> */}

      {/* Lead Page Pixels */}
      {/* {typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.pathname == "/KG2" && <Helmet> */}
      {/* Lead Page Visit Pixel */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsconv;cat=pageconv;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/> */}
      {/* Form Submission Pixel ?? Note - waiting for confirmation on the purpose of this pixel */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsconv;cat=formconv;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/> */}
      {/* </Helmet>} */}
    </>
  );
};

export default KG2Analytics;
